import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  AuthState,
  FactorsState,
  MFAActions,
  PatientState,
  ResultActions,
  ResultsState,
  getMFASelectionStatus,
  logOut,
  refreshSession,
  selectPatientState,
} from '@patient-ui/patient-web/store';
import * as authActions from '@patient-ui/patient-web/store';
import * as patientActions from '@patient-ui/patient-web/store';
import { Patient, ReportHeaderSummary } from '@patient-ui/shared/models';
import { ModalComponent } from '@patient-ui/shared-ui/ui';

import { MfaSetupConfirmationComponent } from '../mfa-setup-confirmation/mfa-setup-confirmation.component';

@Component({
  templateUrl: './portal-layout.component.html',
  styleUrls: ['./portal-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalLayoutComponent implements OnInit, AfterViewInit {
  @ViewChild('emailVerifyModalRef') emailVerifyModal!: ModalComponent;
  @HostBinding('class') cssClass = 'd-flex flex-column vh-100';
  @ViewChild('ieNotificationModalRef', { static: true })
  ieNotificationModalRef!: TemplateRef<unknown>;
  mfaStatus$ = this.mfaStore.select(getMFASelectionStatus);
  destroyed = new Subject();
  patient?: Patient;
  authAttemptsExhausted = false;
  isEligibleToViewResults = false;
  serviceError = false;
  reportHeadersExist = false;
  resultSummary!: ReportHeaderSummary;
  primaryAccountHolder?: Patient;
  isProcessing$ = new BehaviorSubject<boolean>(true);

  constructor(
    private patientStore: Store<PatientState>,
    private modalService: NgbModal,
    private authstore: Store<AuthState>,
    private mfaStore: Store<FactorsState>,
    private activeModal: NgbModal,
    private resultStore: Store<ResultsState>
  ) {}

  ngOnInit(): void {
    this.resultStore.dispatch(ResultActions.clearResults());
    this.patientStore
      .select(selectPatientState)
      .pipe(takeUntil(this.destroyed))
      .subscribe((patientState) => {
        this.patient = patientState.primaryAccountHolder;
      });
    this.msIeVersion();
    this.mfaStatus$.subscribe((data) => {
      if (data === null && this.patient?.emailVerified) {
        this.modalService.open(MfaSetupConfirmationComponent, {
          backdrop: 'static',
          keyboard: false,
        });
      }
    });
    this.mfaStore.dispatch(MFAActions.retriveMFAStatus());
  }

  ngAfterViewInit(): void {
    if (!this.patient?.emailVerified) {
      this.emailVerifyModal.open();
    }
  }

  emailVerifiedHandler(verified: boolean) {
    if (verified) {
      this.patientStore.dispatch(patientActions.setPatientPAHEmailVerified());
      this.activeModal.dismissAll('verified');
    }
  }

  verifyLogOutHandler() {
    this.activeModal.dismissAll('loggingOut');
    this.authstore.dispatch(authActions.logOut({ redirect: '/landing' }));
    sessionStorage.clear();
    window.history.replaceState(null, '', '/');
  }

  openNotificationModal() {
    return this.modalService.open(this.ieNotificationModalRef, {
      backdrop: 'static',
      keyboard: false,
    });
  }

  msIeVersion(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');

    // eslint-disable-next-line no-useless-escape
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      this.openNotificationModal();
      return true;
    } // If another browser, return 0
    else {
      return false;
    }
  }

  handleSession(logoutDecision: boolean) {
    if (logoutDecision) {
      this.authstore.dispatch(logOut({ redirect: '/landing' }));
    } else {
      this.authstore.dispatch(refreshSession());
    }
  }
}
